import React from 'react';
import { Row, Col } from 'react-bootstrap';
import badge1 from '../images/badge-1.png';
import badge2 from '../images/badge-2.png';
import BRAND_CONFIG from '../BRAND_CONFIG';

const DownloadButtons = () => {
    const { links, vendorUUID } = BRAND_CONFIG;

    return (
        <div className="justify-content-center text-center mb-3">
            <a href={links?.ios} className="mx-2" target="_blank" rel="noreferrer">
                <img style={{ width: '110px' }} className="mr-3" src={badge1} />
            </a>
            {vendorUUID !== 'c31f2558-e73a-49eb-8120-c3d388bfdc1d' &&
                (
                    <a href={links?.android} className="mx-2" target="_blank" rel="noreferrer">
                        <img style={{ width: '110px' }} src={badge2} />
                    </a>
                )}
        </div>
    );
};

export default DownloadButtons;
