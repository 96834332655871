import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import queryString from 'query-string';
import { isNumber, keys, lowerCase, upperCase } from 'lodash';
import Tap from '@tapfiliate/tapfiliate-js';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import './style.scss';
import DownloadButtons from '../../components/downloadButtons';
import BRAND_CONFIG from '../../BRAND_CONFIG';
import HeaderLogo from '../../components/header/HeaderLogo';
import { getSessionInfo } from '../../utils/stripe';
import { Button } from 'react-bootstrap';

const { links, logo, analytics, vendorUUID, pages } = BRAND_CONFIG;

const ThankYouPage = ({ location }) => {
    const [checkoutInfo, setCheckoutInfo] = useState({});
    const { session, plan, id } = queryString.parse(location.search);

    useEffect(() => {
        (async () => {
            console.log('fetching session info');
            const response = await getSessionInfo(session);
            console.log('sessioninfo response', response);
            if (response?.status === 200) {
                const { data } = response;
                console.log('sessionInfo: ', response);
                setCheckoutInfo(data);
            }
        })();

        const params = queryString.parse(location.search);
        console.log(params);
    }, []);

    useEffect(() => {
        console.log('checkout info updated, ', checkoutInfo);
        if (keys(checkoutInfo)?.length) {
            console.log('got checkout info : ', checkoutInfo);
            const { total } = checkoutInfo;
            if (analytics?.tapfiliate) {
                if (total === 0) {
                    Tap.trial(
                        id,
                    );
                    console.log('Trial tracked', id);
                } else {
                    Tap.conversion(
                        id,
                        `${total / 100}`,
                        {
                            currency: upperCase(checkoutInfo?.currency),
                        },
                        // For OWNUs vendor, we need to append the currency to the plan name
                        vendorUUID === '8ecc5861-cebf-47db-a78f-f876b8ab453c' ? plan + lowerCase(checkoutInfo?.currency) : plan,
                    );
                    console.log('Conversion tracked', id, total, plan);
                }
            }
            if (window.fbq != null) {
                if (total === 0) {
                    window.fbq('track', 'StartTrial', {
                        currency: 'GBP',
                        product: plan,
                    });
                } else {
                    window.fbq('track', 'Subscribe', {
                        value: total / 100,
                        currency: 'GBP',
                        product: plan,
                    });
                }
            }
        }
    }, [checkoutInfo]);

    useEffect(() => {
        if (analytics?.gtag  && window.gtag != null  && isNumber(checkoutInfo?.total) && !!checkoutInfo?.currency) window.gtag('event', 'purchase', {
                value: checkoutInfo?.total / 100,
                currency: upperCase(checkoutInfo?.currency),
                transaction_id: id,
            });
    },[checkoutInfo]);

    return (
        <Layout>
            <SEO title="Thank You" />
            <div className="page-wrapper">
                <Col />
                <Col className="mt-3 sub-form-container px-3 flex-sm-fill flex--grow-1">
                    <HeaderLogo title={BRAND_CONFIG?.pages?.thankyou?.translations?.heading ?? 'Subscription Confirmed' }logo={logo} logoSize={100} />
                    <p className="text-center">
                        {BRAND_CONFIG?.pages?.thankyou?.translations?.downloadText ?? 'Download the app below and login with the same account you just created.'}
                    </p>
                    <DownloadButtons />
                    {
                        pages.thankYou && (
                            <div className="text-center">
                                <img
                                    src={pages?.thankYou?.imageUrl}
                                    alt="Thank you"
                                    style={{ width: '100%', maxWidth: '300px' }}
                                />
                                <p className="text-center mt-3">
                                    {pages?.thankYou?.additionalText}
                                </p>
                                <Button style={{marginBottom: '1rem'}}>{pages?.thankYou?.ctaText}</Button>
                            </div>
                        )
                    }
                </Col>
                <Row className="info-row mt-5 d-flex align-items-sm-end">
                    <Col>
                        <p className="body-font text-center">{BRAND_CONFIG?.pages?.thankyou?.translations?.needHelp ?? 'Need help?'}</p>
                        <p className="body-font text-center">
                            <a
                                href={links?.support}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {BRAND_CONFIG?.pages?.thankyou?.translations?.supportCenter ?? 'Visit Support Center'}
                            </a>
                        </p>
                    </Col>
                </Row>
            </div>
        </Layout>
    );
};

export default ThankYouPage;
